import React from "react";
import * as PopoverPrimitives from "@radix-ui/react-popover";
import { cn } from "@RHCommerceDev/utils/cn";

const Popover = PopoverPrimitives.Root;

const PopoverTrigger = PopoverPrimitives.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitives.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitives.Content>
>(({ className, children, ...props }, ref) => {
  return (
    <PopoverPrimitives.Portal>
      <PopoverPrimitives.Content
        onOpenAutoFocus={event => {
          event.preventDefault();
        }}
        onCloseAutoFocus={event => {
          event?.preventDefault();
        }}
        className={cn("shadow-popperBox z-[1100]", className)}
        {...props}
        ref={ref}
      >
        {children}
      </PopoverPrimitives.Content>
    </PopoverPrimitives.Portal>
  );
});
PopoverContent.displayName = "PopoverContent";

export { Popover, PopoverTrigger, PopoverContent };
