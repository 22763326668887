export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const countryZipCodesRegex = {
  // CA: /([ABCEGHJKLMNPRSTVXY]\d[A-Z])\s(\d[A-Z]\d)/i,
  CA: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  US: /(?!^00000$)(?!^11111$)(^\d{5}(\-?\d{4})?$)/,
  GB: /^((GIR *0AA)|((([A-PR-UWYZ][A-HK-Y]?\d\d?)|(([A-PR-UWYZ]\d[A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y]\d[ABEHMNPRV-Y])))( *\d[ABD-HJLNP-UW-Z]{2})?))$/i,
  DE: /^[0-9]{5}$/,
  BE: /^[0-9]{4}$/
};

/** [Source](https://stackoverflow.com/questions/28228118/regex-international-phone-numbers-allowing-white-spaces) */

/* updating from {8,14} to {8,15} as part of this ticket https://restorationhardware.jira.com/browse/EE-1264  */
export const phoneRegex =
  /^(?=(?:\D*\d\D*){8,15}$)[ -]*\+?[ -]*(?:\((?:[ -]*\d)+[ -]*\))?[- \d]*/;

export const onlyNumberRegex = /^[0-9]*$/;

export const spaceRegex = /(?!^ +$)^.+$/;

export const giftRegistryRegex =
  /^([A-Za-zÀÁÂÃÄÅÇÈÉÊËßẞœæÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçéèéêëìíîïðòóôõöùúûüýÿñçŒÆ¿¡0-9~`{}[\]|\\!@#$%^&*( )_+-=;:'“",<.>/?])*$/;

export const maxLengthRegex = /^.{1,50}$/;

export const onlyAlphaRegex = /^([a-zA-Z\s]+)$/;

export const nameRegex =
  /^([a-zA-ZÀÁÂÃÄÅÇÈÉÊËßẞŒÆœæÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçéèéêëìíîïðòóôõöùúûüýÿñ\s-\'])+$/;

export const bgGreyHiddenRegex = /^\/checkout\//;

export const firstnameRegex =
  /^([a-zA-ZÀÁÂÃÄÅÇÈÉÊËßẞœæÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçéèéêëìíîïðòóôõöùúûüýÿñçŒÆ¿¡\s-\'!@#\$%\^&\*\(\)_\+={}[\]|\\:;"<>,.?\/~]+)$/;

export const lastnameRegex =
  /^([a-zA-ZÀÁÂÃÄÅÇÈÉÊËßẞ¿œæÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçéèéêëìíîïðòóôõöùúûüýÿñçŒÆ¿¡\s-\'!@#\$%\^&\*\(\)_\+={}[\]|\\:;"<>,.?\/~]+)$/;

export const conciergeRegexForFirstLastName =
  /^[{a-zA-Z0-9L}\s\-\.\+\/\*\$,:()&@#‘'¡]+$/;

export const cityRegex =
  /^([a-zA-ZÇÁÉÍÓÚÝÂÊÎÔÛÀÈÌÒÙÄËßẞŒÆ¿œæÏÖÜŸÃÑÕÅçáéíóúýâêîôûàèìòùäëïöüÿãñõå\-\s.'])+$/;

export const addresslineRegex =
  /^([a-zA-Z0-9ÇÁÉÍÓÚÝÂÊÎÔÛÀÈÌÒÙÄËßẞŒÆ¿œæÏÖÜŸÃÑÕÅçáéíóúýâêîôûàèìòùäëïöüÿãñõå\s-.+/*_,=:;#()&@'¡])+$/;

export const nameAlphaNumRegex =
  /^([a-zA-Z0-9ÇÁÉÍÓÚÝÂÊÎÔÛÀÈÌÒÙÄËßẞŒÆ¿œæÏÖÜŸÃÑÕÅçáéíóúýâêîôûàèìòùäëïöüÿãñõå.\-'])+$/;

export const alphaPlusNumericRegex = /^(?![0-9]*$)[a-zA-Z0-9\s-]+$/;
export const alphaPlusSpecialRegex =
  /^([a-zA-Z\s!@#$%^&*()\-=+_{}\[\]|;:'",.<>?\/\\~`]+)$/;

export const onlyAlphaNumericRegex = /^[a-zA-Z0-9\s-]+$/;
export const onlyAlphaNumericWithDotRegex = /^[a-zA-Z0-9\s-.]+$/;
export const specialCharactersRegex =
  /^([A-Za-zçéâêîôûàèìòùëïüöÃãñÅåæœŒÆ¿ßẞ.,'\-¿¡ ])*$/;
export const alphaNumericWithSpecialCharacters =
  /^([a-zA-Z0-9\s!@#$%^&*()\-=+_{}\[\]|;:'",.<>?\/\\~`]+)$/;

export const codePattern =
  /^([a-zA-ZÀÁÂÃÄÅÇÈÉÊËßẞŒÆœæÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçéèéêëìíîïðòóôõöùúûüýÿñ\s-'".”.\d\+\&\%\*])+$/;

export const cloudCollectionUrlPattern = /\/cloud\-sofa/;

export const duplicatePattern = /\/cat\d+\/rh-all-rugs/g;

export const pageNoUrlPattern = /(\?|&)no=\d+/g;

export const poBoxRegex = /\bP\.?\s?O\.?\s?Box\b|\bPO\*|Po\*|pobox\b/i;

export const periodLookbackRegex = /(?<=\.)\s/;
export const alphabetWithAccentsRegex = /^[a-zA-Z\u00C0-\u00FF\s]+$/;
export const zipCodeRegex = /^[A-Za-z0-9\s\-]{3,10}$/;
