import React, { FC } from "react";
import { cva, VariantProps } from "class-variance-authority";
import clsx from "clsx";

const typoVariants = cva(
  "inline-flex items-center justify-center gap-2 font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      variant: {
        p: "text-[11px] text-black tracking-[0.165px] bg-pink",
        h1: "text-xl text-transform: uppercase",
        caption: "inline text-[11px]",
        error: "font-primary-thin font-light text-xs text-[#CA2022]"
      }
    },
    defaultVariants: {
      variant: "p"
    }
  }
);

interface TypographyProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof typoVariants> {}

const variantType: Record<"caption" | "error", keyof JSX.IntrinsicElements> = {
  error: "p",
  caption: "span"
};
export const Typography: FC<TypographyProps> = ({
  children,
  className,
  variant = "p",
  ...rest
}) => {
  const Tag =
    (variant && variantType[variant] ? variantType[variant] : variant) ?? "p";
  return (
    <Tag
      id={"tailwind-text"}
      className={clsx(typoVariants({ variant }), className)}
      {...rest}
    >
      {children}
    </Tag>
  );
};
