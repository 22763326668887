import React from "react";
import { cn } from "@RHCommerceDev/utils/cn";
import * as DialogPrimitives from "@radix-ui/react-dialog";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import { cva, VariantProps } from "class-variance-authority";

const Drawer = DialogPrimitives.Root;

const DrawerTrigger = DialogPrimitives.Trigger;

const DrawerPortal = DialogPrimitives.Portal;

const DrawerClose = DialogPrimitives.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitives.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitives.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitives.Overlay
    className={cn("fixed inset-0 z-[1300] bg-gray-500/40", className)}
    ref={ref}
    {...props}
  />
));
DrawerOverlay.displayName = "DrawerOverlay";

const drawerContentVariants = cva(
  "flex flex-col fixed inset-y-0 z-[1300] bg-rh-buff shadow-[rgba(0,0,0,0.1)_0px_0px_2px_2px] overflow-y-auto overflow-x-hidden",
  {
    variants: {
      direction: {
        right:
          "right-0 data-[state=open]:animate-slideInFromRight data-[state=closed]:animate-slideOutFromRight",
        left: "left-0 data-[state=open]:animate-slideInFromLeft data-[state=closed]:animate-slideOutFromLeft"
      }
    },
    defaultVariants: {
      direction: "right"
    }
  }
);

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitives.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitives.Content> &
    VariantProps<typeof drawerContentVariants>
>(({ className, direction = "right", ...props }, ref) => (
  <DialogPrimitives.Content
    className={cn(drawerContentVariants({ direction }), className)}
    ref={ref}
    {...props}
  />
));
DrawerContent.displayName = "DrawerContent";

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitives.Title>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitives.Title
    ref={ref}
    className={cn(
      "sticky h-12 sm:-mx-[60px] -mx-6 bg-rh-buff sm:px-[60px] px-6 top-0",
      className
    )}
    {...props}
  >
    {children}
    <DrawerClose className="absolute p-0 right-6 top-5">
      <RHCloseIcon className="w-4 h-4" />
    </DrawerClose>
  </DialogPrimitives.Title>
));
DrawerTitle.displayName = DialogPrimitives.Title.displayName;

const DrawerDescription = DialogPrimitives.Description;

export {
  Drawer,
  DrawerTrigger,
  DrawerPortal,
  DrawerClose,
  DrawerOverlay,
  DrawerContent,
  DrawerTitle,
  DrawerDescription
};
