import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import clsx from "clsx";
import * as React from "react";

const buttonVariants = cva(
  "inline-flex items-center justify-center cursor-pointer gap-2 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-2 disabled:pointer-events-none disabled:bg-[#DDD] [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-white text-black shadow hover:bg-white/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-black text-white shadow-sm hover:bg-black/80",
        link: "text-black underline underline-offset-2 bg-transparent",
        // contained: "text-black underline underline-offset-2 bg-transparent",
        // after the removal of tailwind flag we need to replace the variants by its actual variant type
        text: "text-black bg-transparent", // text is similar to that of link but to make it compatible with material UI component added text
        contained:
          "bg-black text-white shadow-sm hover:bg-black/80 px-[15px] py-[15px]"
      },
      size: {
        default: "h-auto p-4",
        sm: "h-8 px-3 text-xs",
        lg: "h-12 px-8",
        icon: "h-9 w-9",
        none: "py-0"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={clsx(buttonVariants({ variant, size, className }))}
        ref={ref}
        id={"unstyled-button"}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
