import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "@RHCommerceDev/utils/cn";
import RHCloseIcon from "@RHCommerceDev/icon-close";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={cn("fixed inset-0 z-[1300] bg-gray-500/40", className)}
    ref={ref}
    {...props}
  />
));
DialogOverlay.displayName = "DialogOverlay";

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Content
    className={cn(
      "flex flex-col fixed inset-0 z-[1300] bg-rh-buff overflow-y-auto left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] w-fit h-fit",
      className
    )}
    ref={ref}
    {...props}
  />
));
DialogContent.displayName = "DialogContent";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn("sticky top-0 h-12 flex items-center", className)}
    {...props}
  >
    {children}
    <DialogClose className="p-0 ml-auto">
      <RHCloseIcon className="w-4 h-4" />
    </DialogClose>
  </DialogPrimitive.Title>
));
DialogTitle.displayName = "DialogTitle";

const DialogDescription = DialogPrimitive.Description;

export {
  Dialog,
  DialogTrigger,
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogClose,
  DialogTitle,
  DialogDescription
};
