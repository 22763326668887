import { Brand } from "types";

type Options = {
  domainFormat?: "unified" | "legacy";
  location?: Location;
};

export function getBrandedUrl(
  path: string,
  targetBrand: Brand = "RH",
  { domainFormat = "unified", location = window.location }: Options = {}
): string {
  const { host, hostname, protocol } = location;

  let subdomain = "";

  if (host.indexOf("www") !== -1) {
    subdomain = host.split(".").slice(0, -2).join("");
  } else {
    const segments = host.split(".").slice(0, -2);

    subdomain = segments.length > 1 ? segments[1] : segments[0];
  }

  let pathname = path?.trim();

  if (domainFormat === "unified") {
    if (targetBrand === "BH") {
      return `${protocol}//${hostname}${pathname}?context=BeachHouse&catalog=BH`;
    }

    if (targetBrand === "IN") {
      return `${protocol}//${hostname}${pathname}?context=Interiors&catalog=IN`;
    }

    if (targetBrand === "OD") {
      return `${protocol}//${hostname}${pathname}?context=Outdoor&catalog=OD`;
    }

    if (targetBrand === "SH") {
      return `${protocol}//${hostname}${pathname}?context=SkiHouse&catalog=SH`;
    }

    trimPathname();

    if (targetBrand === "BC") {
      return `${protocol}//rhbabyandchild.${hostname}${pathname}`;
    }

    if (targetBrand === "MO") {
      return `${protocol}//rhmodern.${hostname}${pathname}`;
    }

    if (targetBrand === "TN") {
      return `${protocol}//rhteen.${hostname}${pathname}`;
    }

    return `${protocol}//${hostname}${pathname}`;
  } else {
    if (targetBrand === "BH") {
      return `${protocol}//${hostname}${pathname}?context=BeachHouse&catalog=BH`;
    }

    if (targetBrand === "OD") {
      return `${protocol}//${hostname}${pathname}?context=Outdoor&catalog=OD`;
    }

    if (targetBrand === "SH") {
      return `${protocol}//${hostname}${pathname}?context=SkiHouse&catalog=SH`;
    }

    const isAem =
      hostname.includes("adobecqms") || hostname.includes("adobeaemcloud");

    const isProd = !isAem && !hostname.includes("rhnonprod");

    trimPathname();

    if (isProd) {
      if (targetBrand === "BC") {
        return `${protocol}//www.rhbabyandchild.com${pathname}`;
      }

      if (targetBrand === "MO") {
        return `${protocol}//www.rhmodern.com${pathname}`;
      }

      if (targetBrand === "TN") {
        return `${protocol}//www.rhteen.com${pathname}`;
      }

      return `${protocol}//${hostname}${pathname}`;
    } else {
      if (targetBrand === "BC") {
        return `${protocol}//${subdomain}-www.rhbabyandchild.com${pathname}`;
      }

      if (targetBrand === "MO") {
        return `${protocol}//${subdomain}-www.rhmodern.com${pathname}`;
      }

      if (targetBrand === "TN") {
        return `${protocol}//${subdomain}-www.rhteen.com${pathname}`;
      }

      return `${protocol}//${hostname}${pathname}`;
    }
  }

  function trimPathname(): void {
    if (pathname === "/") {
      pathname = "";
    }
  }
}
